import { graphql } from 'gatsby'
import React from 'react'

import { Container, Html, Layout, Section, SEO, SingleHeader } from '../components'
import { contentColorMap, makeContentStyle, textColumnStyle } from '../styles'
import { Site, ThemeColorName } from '../types'

interface ContentTemplateProps {
  location: Location
  readonly data: {
    readonly markdownRemark: any
    readonly site: Site
  }
}

const SingleTemplate = ({ data, location: { pathname } }: ContentTemplateProps) => {
  const {
    markdownRemark: {
      frontmatter: { title, date, image, location },
      html,
      fields: { type }
    }
  } = data
  const isNews = type === 'news'
  const color = contentColorMap[type as keyof typeof contentColorMap] as ThemeColorName
  const content = isNews ? `<p>${date}</p>${html}` : html
  return (
    <Layout currPath={pathname} pageColor={color}>
      <SEO title={title.join(' ')} />
      <Section index={0} color={color} renderBg={true} headerOffset={false}>
        <article css={makeContentStyle(color)}>
          <SingleHeader
            color={color}
            image={image}
            title={title}
            date={!isNews && date}
            location={!isNews && location}
            type={type}
          />
          <Container>
            <Html css={textColumnStyle}>{content}</Html>
          </Container>
        </article>
      </Section>
    </Layout>
  )
}

export default SingleTemplate

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      fields {
        type
      }
      frontmatter {
        date(formatString: "D.M.YYYY")
        title
        location
        image {
          mobile: childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 1000) {
              presentationWidth
              ...GatsbyImageSharpFluid
            }
          }
          desktop: childImageSharp {
            fluid(maxWidth: 2854, maxHeight: 2000) {
              presentationWidth
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
